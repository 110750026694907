import React from 'react';
import Box from '@webAtoms/Box/Box';
import Icon from '@webAtoms/Icon/Icon';
import * as styles from './accordion.module.scss';

export interface AccordionProps {
  isActive?: boolean;
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  mobileOnly?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  isActive = false,
  title,
  children,
  className,
  mobileOnly,
}) => {
  const [active, setActive] = React.useState(isActive);
  React.useEffect(() => {
    setActive(isActive);
  }, [isActive]);

  const onClickToggle = () => {
    setActive(!active);
  };

  return (
    <Box
      className={`${styles.accordion} ${active ? styles.accordionActive : ''} ${
        mobileOnly ? styles.accordionMobileOnly : ''
      } ${className || ''}`}
    >
      <button onClick={onClickToggle} className={`${styles.accordionToggle}`}>
        <span>{title}</span>
        <Icon name="chevronDown" className={styles.accordionToggleIcon} />
      </button>
      <Box className={styles.accordionContent}>
        <Box className={styles.accordionContentInner}>{children}</Box>
      </Box>
    </Box>
  );
};

export default Accordion;
