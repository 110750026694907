import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import decodeDesignWithFloorPlans from '@decoders/DesignWithFloorPlans';
import DesignWithFloorPlans from '@localTypes/DesignWithFloorPlans';

interface QProps {
  exclude?: string;
  children: (designs: DesignWithFloorPlans[]) => React.ReactNode;
}

export default ({ children, exclude }: QProps) => (
  <StaticQuery
    query={query}
    render={data => {
      const designs = decodeDesignWithFloorPlans(
        data.allMarkdownRemark?.edges || []
      );

      const prefilterdDesigns = designs.filter(a => !a.hidden);

      const filteredDesigns = exclude
        ? prefilterdDesigns.filter(a => a.slug !== exclude)
        : prefilterdDesigns;

      return children(filteredDesigns);
    }}
  />
);

export const query = graphql`
  query AllDesigns {
    allMarkdownRemark(
      filter: { fields: { collection: { eq: "design" } } }
      sort: { fields: frontmatter___name, order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          html
          frontmatter {
            name
            title
            hidden
            bookVisitUrl
            productTileBody
            images {
              childImageSharp {
                gatsbyImageData
                # (
                #   placeholder: BLURRED
                #   formats: [AUTO, WEBP, JPG]
                # )
              }
            }
            variants {
              name
              title
              price
              image {
                publicURL
              }
              features {
                bedrooms
                bathrooms
                livingSpaces
                workSpaces
              }
            }
          }
        }
      }
    }
  }
`;
