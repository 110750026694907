import React from 'react';
import Box from '@webAtoms/Box/Box';
import Button from '@webAtoms/Button/Button';
import Carousel, { CarouselSlide } from '@webOrganisms/Carousel/Carousel';
import Card from './Card';
import * as styles from './cardDesign.module.scss';

export type CardDesignProps = {
  cta: string;
  href: string;
  price: number;
  slides: CarouselSlide[];
  title: string;
  className?: string;
};

const CardDesign: React.FC<CardDesignProps> = ({
  cta,
  href,
  price,
  slides,
  title,
  className,
}) => {
  const titleExpl = title.split(' ');
  const [titleLastWord, titleWithoutLastWord] = [
    titleExpl.pop(),
    titleExpl.join(' '),
  ];

  return (
    <Card className={`${styles.cardDesign} ${className || ''}`} to={href}>
      <Box className={styles.cardDesignCarousel}>
        <Carousel type="card" slides={slides} />
      </Box>
      <Box className={styles.cardDesignBody}>
        {titleLastWord === 'range' ? (
          <h3>{title}</h3>
        ) : (
          <h3>
            {titleWithoutLastWord} <span>{titleLastWord}</span>
          </h3>
        )}
        <p>From ${price.toLocaleString('en-AU')} + GST</p>
        <Box className={styles.cardDesignCTA}>
          <Button className={styles.cardDesignCTAButton} icon="arrowRight">
            {cta}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default CardDesign;
