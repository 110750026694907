import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { PageCustomQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Custom from '@webPages/ModularHomes/Custom/Custom';

const IndexPage: React.FC<PageProps<PageCustomQuery>> = ({ data }) => {
  const title = data?.custom?.frontmatter?.title || '';

  const seoTitle = data?.custom?.frontmatter?.seoTitle || title || undefined;
  const seoDescription =
    data?.custom?.frontmatter?.seoDescription ||
    data?.custom?.excerpt ||
    undefined;

  const image = data?.custom?.frontmatter?.heroImage?.publicURL || undefined;

  const columns = (data?.custom?.frontmatter?.columns || []).map(
    (column: any) => ({
      title: column?.title || '',
      body: column?.body || '',
      parts: (column?.parts || []).map((part: any) => ({
        title: part?.title || '',
        body: part?.body || '',
      })),
    })
  );

  const faqSections = (data?.custom?.frontmatter?.faqSections || []).map(
    (section: any) => ({
      title: section?.title || '',
      parts: (section?.parts || []).map((part: any) => ({
        title: part?.title || '',
        body: part?.body || '',
      })),
    })
  );

  const custom = {
    title,
    body: data?.custom?.html || '',
    heroImage: getImage(
      data?.custom?.frontmatter?.heroImage as ImageDataLike
    ) as IGatsbyImageData,
    columns,
    faqSections,
  };

  return (
    <Base
      theme="light"
      title={seoTitle}
      description={seoDescription}
      image={image}
    >
      <Custom {...custom} />
    </Base>
  );
};

export const query = graphql`
  query PageCustom {
    custom: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "custom" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        seoTitle
        seoDescription
        heroImage {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 2000
              height: 650
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        columns {
          title
          body
          parts {
            title
            body
          }
        }

        faqSections {
          title
          parts {
            title
            body
          }
        }
      }
    }
  }
`;

export default IndexPage;
