// extracted by mini-css-extract-plugin
export var customBody = "custom-module--customBody--6E1fN";
export var customFaq = "custom-module--customFaq--V2yNZ";
export var customFaqBody = "custom-module--customFaqBody--PEARJ";
export var customFaqInner = "custom-module--customFaqInner--JULhz";
export var customFaqs = "custom-module--customFaqs--xAVWE";
export var customGrid = "custom-module--customGrid--AlOIj";
export var customHeroImageWrapper = "custom-module--customHeroImageWrapper--3y5NT";
export var customInner = "custom-module--customInner--VLoq7";
export var customSectionHeading = "custom-module--customSectionHeading--K805F";
export var customSectionHeadingNoParts = "custom-module--customSectionHeadingNoParts--uwXb0";
export var customSectionSteps = "custom-module--customSectionSteps--9eovW";