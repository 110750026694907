import React from 'react';
import Box from '@webAtoms/Box/Box';
import Container from '@webAtoms/Container/Container';
import Carousel from '@webOrganisms/Carousel/Carousel';
import AllDesigns from '@staticQueries/AllDesigns';
import CardDesign from '@webMolecules/Card/CardDesign';
import { minPrice } from '@helpers/designs';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Route from '@routes';
import * as styles from './modularHomeDesigns.module.scss';

export interface ModularHomeDesignsProps {
  title?: string;
  exclude?: string;
}

const ModularHomeDesigns: React.FC<ModularHomeDesignsProps> = ({
  title,
  exclude,
}) => (
  <AllDesigns exclude={exclude}>
    {designs => (
      <Box>
        <Container>
          <Box className={styles.modularHomeDesignsInner}>
            <h2>{title || 'Modular home designs'}</h2>

            <Carousel
              type="overflow"
              className={styles.modularHomeDesignsCarousel}
              slides={designs.map(design => ({
                slide: (
                  <Box className={styles.modularHomeDesignsCardWrapper}>
                    <CardDesign
                      className={styles.modularHomeDesignsCard}
                      key={design.slug}
                      cta={`See floorplans`}
                      href={Route.designs(design.slug)}
                      price={minPrice(design)}
                      title={design.title}
                      slides={design.images.map((image, i) => ({
                        slide: (
                          <GatsbyImage
                            loading="eager"
                            image={image}
                            alt={`${design.title} ${i}`}
                          />
                        ),
                      }))}
                    />
                  </Box>
                ),
              }))}
            />
          </Box>
        </Container>
      </Box>
    )}
  </AllDesigns>
);

export default ModularHomeDesigns;
