import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import ReadMore from '@webMolecules/ReadMore/ReadMore';
import SectionHeading from '@webMolecules/SectionHeading/SectionHeading';
import Carousel from '@webOrganisms/Carousel/Carousel';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as Route from '@routes';
import * as styles from './featuredBuilds.module.scss';
import Featured from '@localTypes/Featured';

export interface FeaturedBuildsProps {
  title: string;
  exclude?: string;
  featured: Featured[];
}

const FeaturedBuilds: React.FC<FeaturedBuildsProps> = ({
  title,
  featured,
  exclude,
}) => (
  <Box paddingTop={Spacing.L} paddingBottom={Spacing.L}>
    <SectionHeading href={Route.featuredBuilds()}>{title}</SectionHeading>

    <Carousel
      type="strip"
      slides={featured
        .filter(a => a.title !== exclude)
        .map(featured => ({
          slide: (
            <a href={Route.featuredBuilds(featured.slug)}>
              <GatsbyImage
                loading="lazy"
                alt={featured.title}
                image={featured.image}
              />
            </a>
          ),
          caption: (
            <Box className={styles.featuredBuildsReadMoreWrapper}>
              <ReadMore href={Route.featuredBuilds(featured.slug)}>
                {featured.title}
              </ReadMore>
            </Box>
          ),
        }))}
    />
  </Box>
);

export default FeaturedBuilds;
