import React from 'react';
import * as styles from './sectionHeading.module.scss';
import Container from '@webAtoms/Container/Container';
import Text, { FontStyle } from '@webAtoms/Text/Text';

export interface SectionHeadingProps {
  children?: React.ReactNode;
  href?: string;
}

const SectionHeading: React.FC<SectionHeadingProps> = ({ children, href }) => (
  <Container className={styles.sectionHeading}>
    <Text semantic="h2" fontStyle={FontStyle.Hero}>
      {href ? <a href={href}>{children}</a> : children}
    </Text>
  </Container>
);

export default SectionHeading;
