import { AllDesignsQuery } from '@generated/GraphqlTypes';
import { orEmptyString } from './prelude';
import DesignWithFloorPlans from '@localTypes/DesignWithFloorPlans';
import FloorPlan from '@localTypes/FloorPlan';
import { IGatsbyImageData, getImage } from 'gatsby-plugin-image';

const decodeDesignWithFloorPlans = (
  edges: any[] = []
): DesignWithFloorPlans[] =>
  edges.map((edge: any) => {
    const floorPlans: FloorPlan[] = (edge?.node?.frontmatter?.variants || [])
      .map((variant: any) => {
        if (!variant) {
          return;
        }
        const floorPlan: FloorPlan = {
          name: orEmptyString(variant.name),
          designNameSlug: `${edge?.node?.fields?.slug}`,
          title: orEmptyString(variant.title),
          price: variant.price || 0,
          drawing: variant.image?.publicURL || '',
          features: new Map(
            Object.keys(variant.features || {}).map(a => [
              a,
              (variant.features as any)[a] || 0,
            ])
          ),
        };

        return floorPlan;
      })
      .filter(Boolean) as FloorPlan[];

    const features = floorPlans.reduce((acc, floorPlan) => {
      floorPlan.features.forEach((v, k) => {
        const a = acc.get(k);
        if (a) {
          acc.set(k, [...a, v]);
        } else {
          acc.set(k, [v]);
        }
      });
      return acc;
    }, new Map<string, number[]>());

    return {
      slug: `${edge?.node?.fields?.slug}`,
      name: orEmptyString(edge?.node?.frontmatter?.name),
      title: orEmptyString(edge?.node?.frontmatter?.title),
      hidden: edge?.node?.frontmatter?.hidden ?? false,
      features,
      floorPlans,
      totalAmountOfFloorPlans: floorPlans.length,
      bookVisitUrl: edge?.node?.frontmatter?.bookVisitUrl || undefined,
      images: (edge?.node?.frontmatter?.images || [])
        .map((image: any) => getImage(image))
        .filter(Boolean) as unknown as IGatsbyImageData[],
    };
  });

export default decodeDesignWithFloorPlans;
