import DesignWithFloorPlans from '@localTypes/DesignWithFloorPlans';
import FloorPlan from '@localTypes/FloorPlan';
import FloorPlanDetailed from '@localTypes/FloorPlanDetailed';

export type FilterArr = { key: string; values: number[] }[];

export const minPrice = (design: DesignWithFloorPlans): number =>
  design.floorPlans.reduce(
    (min, floorPlan) =>
      floorPlan.price < min || min === 0 ? floorPlan.price : min,
    0
  );

export const maxPrice = (design: DesignWithFloorPlans): number =>
  design.floorPlans.reduce(
    (max, floorPlan) => (floorPlan.price > max ? floorPlan.price : max),
    0
  );

export const countFloorPlans = (designs: DesignWithFloorPlans[]): number =>
  designs.reduce((count, design) => count + design.floorPlans.length, 0);

export const toFloorPlans = (
  designs: DesignWithFloorPlans[],
  min = 1
): FloorPlan[] =>
  designs.reduce(
    (acc, design) => [
      ...acc,
      ...(design.floorPlans.length > min ? design.floorPlans : []),
    ],
    [] as FloorPlan[]
  );

export const filterFloorPlans = (
  floorPlans: FloorPlanDetailed[],
  filterArr: FilterArr
): FloorPlanDetailed[] =>
  floorPlans.filter(floorPlan =>
    filterArr.reduce((result: boolean, { key, values }) => {
      const value = floorPlan.features.get(key);
      return result ? (value ? values.includes(value) : false) : false;
    }, true)
  );

export const filterArrToString = (filterArr: FilterArr): string => {
  const searchParams = new URLSearchParams();
  filterArr.forEach(({ key, values }) => {
    searchParams.append(key, values.map(a => a.toString()).join(','));
  });
  return searchParams.toString();
};

export const filterArrFromString = (str: string): FilterArr => {
  const searchParams = new URLSearchParams(str);
  const filterArr: FilterArr = [];
  searchParams.forEach((value, key) => {
    filterArr.push({
      key,
      values: value
        .split(',')
        .map(a => parseFloat(a))
        .filter(a => !!a),
    });
  });
  return filterArr;
};
