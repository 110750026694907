import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import FeaturedBuilds from '@webOrganisms/FeaturedBuilds/FeaturedBuilds';
import decodeFeatured from '@decoders/Featured';

interface Props {
  title?: string;
  exclude?: string;
}

export default ({ title, exclude }: Props) => (
  <StaticQuery
    query={query}
    render={data => {
      const featured = decodeFeatured(data?.featured?.edges || [])
        .filter(f => !f.hideOnIndex)
        .filter(a => a.slug !== exclude)
        .sort((a, b) => {
          if (a.date && b.date) {
            return new Date(b.date).getTime() - new Date(a.date).getTime();
          } else if (a.date) {
            return -1;
          } else if (b.date) {
            return 1;
          }
          return 0;
        })
        .slice(0, 12);

      const props = {
        title: title || data?.featuredBuilds?.frontmatter?.title || '',
        featured,
      };

      return <FeaturedBuilds {...props} />;
    }}
  />
);

export const query = graphql`
  query SectionFeaturedBuilds {
    featured: allMarkdownRemark(
      filter: { fields: { collection: { eq: "featured" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            tags
            date
            hideOnIndex
            thumb {
              childImageSharp {
                gatsbyImageData(
                  width: 924
                  height: 520
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, JPG]
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
